/* Add styles for the container */
.container-fluid {
    background-color: black; /* Change the background color to blue */
  }
  
  /* Add styles for the "Who We Are" content */
  .who-we-are {
    max-width: 800px; /* Set the maximum width for the content */
    margin: 0 auto; /* Center the content horizontally */
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.979); /* Set the background color to white */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Add styles for the paragraphs inside "Who We Are" content */
  .who-we-are p {
    text-align: justify; /* Align paragraphs properly */
    margin-bottom: 20px; /* Add some bottom margin to separate paragraphs */
  }

  /* Banner.css */
.interact-button {
    background-image: linear-gradient(to bottom, #0e6fed, #286ec4, #27adef);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Update to 4 columns */
    gap: 20px;
    justify-items: center;
  }
  
  .service-item {
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
  }
  
  .service-item img {
    max-width: 100%;
    height: auto;
  }
  
  
  .service-item.empty {
    visibility: hidden; /* Hide empty placeholder */
  }
  
  