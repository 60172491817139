.card {
    width: 100%;
    height: 100%;
    overflow: hidden; /* Ensure image doesn't overflow the card */
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Or try 'fill' based on your preference */
  }
  