/* AdvancedAnalyticsPage.css */

.advanced-analytics-page {
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .introduction {
    background-color: #f0f0f0;
    padding: 50px 0;
    text-align: center;
  }
  
  .features {
    background-color: #f9f9f9;
    padding: 50px 0;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .feature {
    text-align: center;
  }
  
  .feature img {
    width: 150px;
    border-radius: 50%;
  }
  
  .benefits {
    background-color: #f0f0f0;
    padding: 50px 0;
  }
  
  .benefits-list {
    display: flex;
    justify-content: space-around;
  }
  
  .benefit {
    text-align: center;
  }
  
  .benefit img {
    width: 100px;
    border-radius: 50%;
  }
  
  .cta {
    text-align: center;
    padding: 50px 0;
  }
  
  .cta button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cta button:hover {
    background-color: #0056b3;
  }
  