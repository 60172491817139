Link:hover {
    color: #999933;
}

.items{
    border: 1px solid white;
    justify-content: center;
}

.button{
    background-color: #999933;
}

.navbar-toggler{
    /* color: white; */
    /* border-color:white */
    /* background-color: white; */
    border: 1px solid white;
}
.line{
    color: white;
    border: 1px;
    margin: 6px;
}