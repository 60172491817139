.digital-twin-page {
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .introduction {
    background-color: #f0f0f0;
    padding: 50px 0;
    text-align: center; /* Center align text */
    color: black; 
  }
  
  .benefits {
    padding: 50px 0;
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .benefit {
    text-align: center;
  }
  
  .benefit img {
    width: 150px;
    border-radius: 50%;
  }
  
  .benefit p {
    margin-top: 10px;
  }
  
  .how-it-works,
  .case-studies {
    background-color: #f9f9f9;
    padding: 10px 0;
    color: black; 
  }
  
  .case-study {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .case-study img {
    width: 200px;
    margin-right: 20px;
  }
  
  .cta {
    text-align: center;
    padding: 50px 0;
  }
  
  .cta button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cta button:hover {
    background-color: #0056b3;
  }

  .card:hover {
    /* Add your hover effect styles here */
    box-shadow: 0 0 10px rgba(8, 192, 5, 0.641); /* Example: Adds a shadow effect */
  }
  
  