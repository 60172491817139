.App {
  text-align: center;
}

.main {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.zoom-out-element {
  transition: transform 0.3s ease;
  /* Add a smooth transition for the zoom effect */
}

.zoom-out-element:hover {
  transform: scale(1.1);
  /* Zoom out to 80% of the original size on hover */
  cursor: pointer;
  /* Change the cursor to indicate interactivity */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  display: flex;
}

.service {
  margin-top: 100px;
}

.cursor {
  cursor: pointer;
}

.links {
  text-decoration: none;
  color: white;
}

li:hover,
a:hover {
  color: #008000;
}

.cust-btn {
  background-color: rgb(0, 223, 152);
  border: 0px;
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.cust-btn:hover {
  background-color: rgb(240, 240, 240);
  border: 0px;
  color: black;
  padding: 10px;
  border-radius: 10px;
}


@media screen and (min-width: 1240px) and (max-width: 2240px) {
  .heroImage {
    height: 500px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1240px) {
  .heroImage {
    height: 500px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .heroImage {
    height: 500px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .heroImage {
    height: 400px;
  }
}

@media screen and(min-width: 320px) {
  .heroImage {
    height: 100px;
  }
}