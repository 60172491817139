.digital-twin-page {
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.introduction {
  background-color: #ffffff;
  padding: 20px 0;
  text-align: center; /* Center align text */
  color: black; 
}

.how-it-works,
.case-studies {
  background-color: #ffffff;
  padding: 40px 0;
  color: black; 
}

.cta {
  text-align: center;
  padding: 50px 0;
}

.cta button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.cta button:hover {
  background-color: #0056b3;
}

.card:hover {
  /* Add your hover effect styles here */
  box-shadow: 0 0 10px rgba(4, 112, 221, 0.641); /* Example: Adds a shadow effect */
}
